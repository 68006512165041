import { Box, Typography, useMediaQuery } from "@mui/material"
import { theme } from "../Theme";
import CoachingStepper from "../components/CoachingStepper";
import React from "react";

export const GZ: React.FC = () => {
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    
    return (
        <Box justifySelf='center' textAlign="center" display="flex" flexDirection="column">
            <Typography color="primary" variant={sm?"h4":'h6'} component="h1" marginTop={sm?'200px': '100px'}  marginBottom={sm?'30px':'20px'} ><strong>Ich coache dich für eure Hochzeit!</strong></Typography>
              <CoachingStepper />       
              <Typography color="primary" variant={sm?"h5":'h6'}  marginTop={sm?'100px': '40px'} marginLeft={'10px'} marginRight={'10px'} marginBottom={'10px'} >Ein unverbindliches Erstgespräch kannst du <span onClick={() => window.open('https://calendly.com/fiona-paola/30min', '_blank')} style={{cursor: 'pointer', textDecoration: 'underline'  }}>hier</span> buchen</Typography>
         
        </Box>
    )
    
}

export const GZ2: React.FC = () => {
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box justifySelf='center' textAlign="center" display="flex" flexDirection="column">
            <Typography color="primary" variant={sm?"h5":'h6'} component="h1" marginTop={sm?'100px': '50px'} marginBottom={'30px'}><strong>{sm?'Glücklich und Zufrieden Details': <React.Fragment>Glücklich und Zufrieden<br/>Details</React.Fragment>}</strong></Typography>
            <Typography variant="body1" textAlign="left" marginTop={'20px'} marginBottom={'20px'} className="bulletList">
                    <ul style={{display: 'table', margin: '0 auto', fontSize: '0.8rem'}} >
                    <li>Mein ganzheitliches Coaching begleitet dich in den ersten 6 Monaten deiner Hochzeitsplanung. </li>
                    <li>Du benötigst keine Vorkenntnisse du solltest nur den groben Rahmen eurer Hochzeit kennen. </li>
                    <li>Wir ordnen die verschiedenen Organisationschritte deiner Planung so, dass du damit gut zurechtkommst und dich nicht überlastet fühlst. </li>
                    <li>Die Themen, welche wir innerhalb der Coachings angehen reichen von Emotionen & Prioritäten über Herausforderungen bis hin zu deinem Wohlfühlfaktor. </li>
                    <li>Wir lösen Gedankensätze, die sich negativ auf dich ausüben, wir schaffen Klarheit in deinen Gedanken & du lernst nachhaltig mit Stresssituationen umzugehen. </li>
                    <li>Unser Ziel ist, dass du glücklich & zufrieden aus dem Coaching gehst und bereit bist für Eure wundervolle Hochzeit. </li>
                    <li>Im Glücklich & Zufrieden Brautcoaching werden wir uns 6-mal zu einem Online-Coaching treffen und die jeweiligen Phasen durchlaufen. </li>
                    <li>Ich möchte, dass du weißt: Coaching bedeutet mentale Arbeit, d.h. es ist immer Platz für deine Gedanken und Herausforderungen. </li>
                    <li>Eckdaten: 6 Monate – 6 Coachings - 60€ monatl. – Arbeitsblätter – Support via Instagram oder WhatsApp  </li>
                </ul>                
            </Typography>
        </Box>
    )
}