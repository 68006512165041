import { Grid, Typography, useMediaQuery } from "@mui/material"
import clsx from "clsx"
import { CoachingGoodToKnow } from "../subpages/CoachingGoodToKnow"
import { CoachingIntro } from "../subpages/CoachingIntro"
import { GZ } from "../subpages/GZ"
import { theme } from "../Theme"

export const CoachingDesktop: React.FC = () => {
    const biggerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

   return (<>
            <div className='slide'>
              <div className={clsx({"fp-bg2":biggerThanSm, "fp-auto-height-responsive":true})}>
                <GZ />
              </div>
            </div>
            </>
   )
}