import { Avatar, Card, CardContent, CardHeader, CardMedia, Dialog, DialogProps, DialogTitle, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import DefaultImage from '../static/Blumenbild.png';
import CloseIcon from '@mui/icons-material/Close';

export interface CardDialogProps extends DialogProps {
  onOpen?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  cardMedia?: typeof CardMedia;
  content: string;
  title?: string;
  date: string;
  author: string;
}

export const CardDialog: React.FC<CardDialogProps> = props => {
  if(props.onOpen){
    props.onOpen();
  }
  
  return (
    <Dialog onClose={props.onClose}  {...props}>
      <DialogTitle>        
        <IconButton
          aria-label="close"
          onClick={() => {
            if(props.onCancel){
              props.onCancel();
            }
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          <CloseIcon/>
        </IconButton></DialogTitle>
      <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'transparent'}}>       
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: 'rgb(5, 58, 74)', color: 'white' }} aria-label="author">
              {props.author}
            </Avatar>
          }
          title="News"
          subheader={props.date}
       />
      <CardMedia
        component="img"
        alt="Lilien"
        height="20%"
        style={{maxHeight: '250px'}}
        image={DefaultImage}
      />
      <CardContent sx={{height: '80%', overflow:'scroll', marginBottom: '30px' }}>
      <div >
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body1" color="text.secondary" >
          <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </Typography>
        </div>
        </CardContent>
      
      </Card>
    </Dialog>
  );
} 

