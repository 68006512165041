import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './Theme';
import Logo from './static/FionaPaolaLogo.svg';
import './App.scss';
import Box from '@mui/system/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReactFullpage, {fullpageApi} from '@fullpage/react-fullpage';
import { Popover, Button, ButtonGroup } from '@mui/material';
import { News } from './pages/News';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Team } from './pages/Team';
//import IframeResizer from 'iframe-resizer-react';
import { Impressum } from './pages/Impressum';
import { CoachingDesktop } from './pages/CoachingDesktop';
import { CoachingMobile } from './pages/CoachingMobile';
import { Kontakt } from './pages/Kontakt';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Downloads } from './pages/Download';

type Anchor = 'Home' | 'News' | 'Download' | 'Coaching' | 'Team' | 'Kontakt' | 'Impressum';
const anchors: Anchor[] = ['Home', 'News', 'Download' , 'Coaching',  'Team', 'Kontakt', 'Impressum'];

const menuMap: {[key in Anchor]: number} = {
  'Home': 0,
  'News': 0,
  'Download': 0,
  'Coaching': 1,
  'Team': 2,
  'Kontakt': 3,
  'Impressum': 3,
}


function App() {

  const [value, setValue] = React.useState(0);
  const [api, setApi] = React.useState<fullpageApi | null>(null);
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<JSX.Element>();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any, newValue: any) => {
    console.debug(newValue);
      setValue(newValue);   
    
  };

  const open = Boolean(anchorEl);


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
       <AppBar position="absolute" id="menu">
          <Toolbar className='toolbar'>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignSelf: 'self-start' }}>
            <Tabs tabIndex={0} aria-label="Navigation" value={value} onChange={handleChange}  variant="fullWidth" allowScrollButtonsMobile >
              <Tab disableRipple className="hoverTab" label="HOME" iconPosition="end" icon={<ExpandMore/>}
              onClick={(ev) => 
              {
                  ev.preventDefault();       
                  setPopoverContent(
                    <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="text"
                    size={sm?"medium":"small"}
                    disableElevation
                    disableRipple
                    fullWidth
                  >
                    <Button key="one" onClick={() => {
                      api?.moveTo(1,0);
                      handleClose();
                    }
                    }>HOME</Button>
                    <Button key="two" onClick={() => {
                        api?.moveTo(2,0);
                        handleClose();
                      }
                    }>NEWS</Button>
                    <Button key="three" onClick={() => {
                        api?.moveTo(3,0);
                        handleClose();
                      }
                    }>DOWNLOADS</Button>
                  </ButtonGroup>
                  );
                  handleClick(ev);
                }
              }
                />
              <Tab disableRipple className="hoverTab" label="COACHING" iconPosition="end" onClick={() => api?.moveTo(4,0)} />
              <Tab disableRipple className="hoverTab" label="TEAM" onClick={() => api?.moveTo(5,0)}  />
              {/* <Tab disableRipple className="hoverTab" label="TERMINE" onClick={() => {}}  /> */}
              <Tab disableRipple className="hoverTab" label="KONTAKT" iconPosition="end" icon={<ExpandMore />} onClick={(ev) => 
              {
                  ev.preventDefault();       
                  setPopoverContent(
                    <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical contained button group"
                    variant="outlined"
                    size={sm?"medium":"small"}
                    disableElevation
                    disableRipple
                  >
                    <Button key="appointment" onClick={() => {
                       setTimeout(function() {
                        window.open('https://calendly.com/fiona-paola/30min', '_blank');
                     }, 200);
                      handleClose();
                    }
                    }>TERMINE</Button>
                    <Button key="kontakt" onClick={() => {
                        api?.moveTo(6,0);
                        handleClose();
                      }
                    }>KONTAKT</Button>
                  </ButtonGroup>
                  );
                  handleClick(ev);
                }
              } />
              
            </Tabs>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}           
              marginThreshold={1}
            >
              {popoverContent}
            </Popover>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignSelf: 'self-start' }} />
          </Toolbar>
        </AppBar>
      <ReactFullpage
      debug
        licenseKey = {"5D06C6C5-13594984-AF94145A-83BB7998"}
        scrollingSpeed = {1000} /* Options here */
        anchors = {anchors}
        css3 = {true}
        scrollOverflowReset
        easing = {'easeInOutCubic'}
        easingcss3 = {'ease'}
        loopBottom={false}
        animateAnchor={true}
        scrollOverflow={true}
        loopHorizontal={false}
        sectionsColor = {['white', '#EFF0F2', '#aec4b9', '#EFF0F2',  '#053a4a', '#aec4b9','#EFF0F2', 'blue', 'purple', 'yellow']}
        onLeave={(origin, destination, direction) => {
          setValue(menuMap[destination.anchor as unknown as Anchor]);
        }}
        //key={md?"largeFP":"smallFP"}
        resetSliders

        render={({ state, fullpageApi }) => {
          if(!api){
            setApi(fullpageApi);
          } 
          
          return (
            <ReactFullpage.Wrapper>
                
              <div id="landingPage" className="section fp-auto-height-responsive">
                <div className="fp-bg fp-auto-height-responsive">
                  <Box justifySelf='center' className="imgBox"><img src={Logo} style={{marginBottom: "25%"}} alt="Logo Fiona Paola Coaching" /></Box>
                </div>
              </div>
              <div id="newsSection" className="section fp-auto-height-responsive">
                <div className="slide">
                  <News fullpageApi={fullpageApi}/>
                </div>
              </div>
              <div id="downloadSection" className="section fp-auto-height-responsive">
                  <Downloads />
              </div>
              <div id="coachingsSection" className="section fp-auto-height-responsive">
                <CoachingDesktop />
              
              </div>
              
              <div id="teamSection" className="section fp-auto-height-responsive">
              <Box justifySelf='center' textAlign="left" display="flex" flexDirection="column" padding={sm?'3%':'5%'} paddingTop={'8%'} marginTop={sm?'3%':'5%'} marginBottom={sm?'3%':'5%'}>
                <Team />
              </Box>
              
              </div>
              <div id="contactSection" className="section fp-auto-height-responsive">
                  <Kontakt />
              </div>
              <div id="impressumSection" className="section">
                <Impressum />
              </div>
              
            </ReactFullpage.Wrapper>
          );
        }}
    />
      </ThemeProvider>
    </div>
  );
}

export default App;
