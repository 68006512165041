import { createTheme, responsiveFontSizes, Theme, } from '@mui/material/styles';
import "@fontsource/lora";

const defaultTheme = createTheme();

export const theme: Theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#053a4a',
    },
    secondary: {
      main: '#4a2363',
    },
    background: {
      default: '#aec4b9',
    },
  },
  typography: {
    "fontFamily": "Lora",
    body1: {
      "fontSize": '16px',
    },
    [defaultTheme.breakpoints.up("lg")]: {
      "fontSize": '18px',
    },
    [defaultTheme.breakpoints.down("sm")]: {
      "fontSize": '14px',
    }
   }
}));