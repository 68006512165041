import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActions, Grid, useMediaQuery } from '@mui/material';
import FionaPaola from '../static/FionaPaola.png';
import { theme } from '../Theme';

export const Team: React.FC = () => {
    const smallOrLess = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container>
      <Grid item xs={12} sx={{
      display: { xs: "none", sm: "none", md: "block" }
      }}>
        <Typography component='div' variant={"h5" } color="white" textAlign={'center'}>
          <strong>„Coaching ist mentale Arbeit und führt dich vor allem immer zu dir selbst zurück“</strong>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignContent={'center'} justifyContent={'center'}>
        <Card variant={'elevation'} sx={{ maxWidth: 800 }} elevation={5} className={'teamCard'}>
            <CardMedia
              component="img"
              height={smallOrLess?"200":"400"}
              image={FionaPaola}
              alt="Bild von Fiona-Paola"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" textAlign="center" component="div">
                Fiona-Paola
              </Typography>
              <Typography variant="body2" textAlign="left" className="bulletList">
                <ul style={{display: 'table', margin: '0 auto'}}>
                    <li><strong>Berufliche Leidenschaft:</strong> Coaching von Bräuten</li>
                    <li><strong>Startschuss:</strong> ausgebildete Veranstaltungskauffrau (IHK) </li>
                    <li><strong>3 Charakterzüge:</strong> perfektionistisch, tolerant, höflich </li>
                    <li><strong>Das geht immer:</strong> Pommes & Musik </li>
                    <li><strong>Lieblingsjahreszeit:</strong> Herbst </li>
                </ul>
              </Typography>
            </CardContent>
            {
              smallOrLess && <CardActions>
              <Typography variant="caption" color="text.secondary">
              <strong>„Coaching ist mentale Arbeit und führt dich vor allem immer zu dir selbst zurück“</strong>
              </Typography>
              </CardActions>
            }
        </Card>
        </Box>
      </Grid>
    </Grid>
  );
}