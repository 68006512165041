import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import CalculateIcon from '@mui/icons-material/Calculate';
import { theme } from "../Theme";

export const Downloads: React.FC = () => {
    const biggerThanSm = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box  alignItems='center' justifyContent='start' textAlign="left" display="flex" flexDirection="column" marginTop={biggerThanSm?'3%':'5%'} marginBottom={biggerThanSm?'3%':'5%'}>
        <div style={{border: '3px dotted #FFF', padding: biggerThanSm?'10% 5% 10% 5%':'20% 10% 20% 10%'}}>
      <Typography variant={biggerThanSm?"h5":'h6'} color="primary" component="h1" textAlign='center' paddingBottom={biggerThanSm?'40px':'20px'}>
        <strong>Downloads:</strong>
        </Typography>  
{/*         <Box
          display="flex"
          flexDirection='column'
          marginRight="1rem"
          marginBottom="1rem"
          maxWidth={biggerThanSm?'250px':'200px'}
        >
      <Button variant="outlined" style={{margin: '5px 5px 0 5px'}} startIcon={<CalculateIcon />} onClick={() => window.open('/Budgetplanung.xlsx','_blank')}>
        <Box component="i" marginRight=".5rem" />
        <Typography>
          Budgetplaner
        </Typography>
        
      </Button>
      <Typography color={'primary'} style={{margin: '0 20px 20px 20px'}} borderBottom={'1px dotted #053a4a'} textAlign={'center'} variant="caption">
            Mit meinem Budgetplaner kannst du problemlos die Finanzen deiner Hochzeit planen
        </Typography>
    </Box> */}
    </div>
      </Box>
    )
}