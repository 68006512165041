import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Blumenbild from '../static/Blumenbild.png';
import { Avatar, CardHeader, Divider, Theme, useMediaQuery } from '@mui/material';
import { fullpageApi } from '@fullpage/react-fullpage';
import { useModal } from 'mui-modal-provider';
import { CardDialog } from './CardDialog';
import truncate from 'lodash.truncate';
import DOMPurify from 'dompurify';

export const NewsCard: React.FC<{title: string, content: string, date: string, fullpageApi?: fullpageApi}> = ({title, content, date, fullpageApi}) => {
  const isNotPhone = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const isBig = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const smOrLower = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const contentMaxLength = isBig?2000:isNotPhone?1000:700;
  const { showModal } = useModal();
  const outputDate = new Date(date).toLocaleString(undefined,{dateStyle: 'medium',timeStyle: 'short'});

  return (
    <>
    <Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'transparent'}}>
           <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'rgb(5, 58, 74)', color: 'white' }} aria-label="author">
            FP
          </Avatar>
        }
        style={{color: '#053a4a'}}
        title={<strong>News</strong>}
        subheader={outputDate}
      />
      <Divider />
      {!smOrLower && <CardMedia
        component="img"
        alt="Blumenbild"
        height="20%"
        image={Blumenbild}
      />}
      <CardContent sx={{height: smOrLower?'100%':'80%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
       <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} >
        <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
        <Typography color={'primary'} paddingBottom={'10px'} variant="h5" component="div">
          {title}
        </Typography>
        <Typography lineHeight={isBig?1.2:1} color={'black'} >
          <div dangerouslySetInnerHTML={{ __html: truncate(DOMPurify.sanitize(content), {
  'length': contentMaxLength,
}) }} />
        </Typography>
        </div>
        <CardActions>
       {/*  <Button size="small">Share</Button> */}
        {
        content.length > contentMaxLength && 
        <Button variant="outlined"
          onClick={(e) => {
              e.preventDefault();
              
              if(fullpageApi){
                fullpageApi.setAllowScrolling(false);
              };
              const modal = showModal(CardDialog,
                {
                  title,
                  author: "FP",
                  fullScreen: true,
                  date: outputDate,
                  onCancel: () => {
                      modal.hide();
                  },
                  onClose: () => {
                    if(fullpageApi){
                      fullpageApi.setAllowScrolling(true);
                    }; 
                  },
                  content
              });
              //setOpen(true);
            }
          }>Weiterlesen
        </Button>
        }
      </CardActions>
      </div>
      </CardContent>
      
    </Card>
    
    </>
  );
}