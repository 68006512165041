/* tslint:disable */

import { fullpageApi } from '@fullpage/react-fullpage';
import { Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSprings, animated, interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { NewsCard } from '../components/NewsCard';
import arrows from '../static/arrows.png';
import { theme } from '../Theme';
import { Directus } from '@directus/sdk';

const directus = new Directus('https://cms.reypka.com');

export type FioNews = {
  title: string;
  content: string;
  releaseDate: string;
};

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i: number) => ({ x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 })
const from = (_i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r: number, s: number) =>
  `perspective(5000px) rotateX(15deg) rotateY(${r / 8}deg) rotateZ(${r}deg) scale(${s})`

export const News: React.FC<{ fullpageApi: fullpageApi }> = ({fullpageApi}) => {
  const smOrLower = useMediaQuery(theme.breakpoints.down('sm'));
  const [cards, _setCards] = useState<JSX.Element[]>([]);
  const cardsRef = React.useRef(cards);
  const setCards = (data: any) => {
    cardsRef.current = data;
    _setCards(data);
  };

  const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
  const [props, api] = useSprings(cards.length, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const rightKey = 'ArrowRight';
  const leftKey = 'ArrowLeft';

  const downHandler: (key:any) => void = ({ key }) => {
    if (key === rightKey) {

      api.start(i => {
        if (i + gone.size !== cardsRef.current.length - 1) return
        gone.add(cardsRef.current.length + gone.size - 1)
        return { x: 200 + window.innerWidth, y: i * -4, scale: 0.5, rot: -10 + Math.random() * 20, delay: i * 100 }
      } )
      if ( gone.size === cardsRef.current.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => to(i))
      }, 600)
    }
    if (key === leftKey) {
      api.start(i => {
        if (i + gone.size !== cardsRef.current.length - 1) return
        gone.add(cardsRef.current.length + gone.size - 1)
        return { x: -200 -window.innerWidth, y: i * -4, scale: 0.5, rot: -10 + Math.random() * 20, delay: i * 100, config: {velocity: 0.01} }
      } )
      if ( gone.size === cardsRef.current.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => to(i))
      }, 600)
    }
  }
  // If released key is our target key then set to false
  const upHandler: (key:any) => void = ({ key }) => {
    
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    directus
    .items<string,FioNews>('News')
    .readByQuery({
      limit: 10
    })
    .then((res) => {
      if(typeof res.data !== undefined){
        const allNews: FioNews[] = res.data as unknown as FioNews[];
        const mediaCards = allNews?.map((news) => 
          (<NewsCard title={news.title} content={news.content} date={news.releaseDate} fullpageApi={fullpageApi} />));
        setCards(mediaCards);
      }
    });
    
  }, [fullpageApi]);

  const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
    const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
    const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
    if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
    api.start(i => {
      if (index !== i) return // We're only interested in changing spring-data for the current spring
      const isGone = gone.has(index)
      const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
      const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
      const scale = down ? 1.1 : 1 // Active cards lift up a bit
      return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
    })
    if (!down && gone.size === cards.length)
      setTimeout(() => {
        gone.clear()
        api.start(i => to(i))
      }, 600)
  })

  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <>
    
    <div className="deckContainer flex fill center" tabIndex={0}>
    <Typography color={'primary'} variant="body1" style={{placeSelf: 'flex-end', marginBottom: smOrLower?'20px':'30px'}}>{smOrLower?'Swipe':<>🖰 oder <img style={{marginTop: '2px'}} src={arrows} width={"24px"} height={"24px"} alt={"arrow keys"}></img></>} um zu blättern</Typography>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div className={'deck'} key={i} style={{ x, y }}>
          {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
          <animated.div
            {...bind(i)}
            style={{ transform: interpolate([rot, scale], trans) }}
          >
              {cards[i]}
            </animated.div>
        </animated.div>
      ))}
    </div>
    </>
  )
}