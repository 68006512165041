import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { StepContent, StepLabel, Theme, useMediaQuery } from '@mui/material';

const steps = ['Glücklich & Zufrieden Brautcoaching', 'entspannt geplant Brautcoaching', 'Kurz & Knackig Brautcoaching'];

const step1MobileText = `
\u2218 1x Vorgespräch (60 Min.) wir lernen uns kennen und besprechen die relevanten Themen für dich 
\u2218 6x Brautcoaching (je 90 Min.) wir besprechen den aktuellen Stand, du bekommst Infos für ein bestimmtes Thema worüber wir ausführlich sprechen und es deinen Wünschen und Prioritäten anpassen, es ist Zeit für deine Fragen und Anregungen
\u2218 6 Monate Laufzeit & Support via Instagram, du kannst mir Mo-Fr all deine Fragen, aktuelle Stände und Infos geben, wir stehen im ständigen Austausch miteinander, wenn du möchtest.
\u2218 Dieses Gesamtpaket bekommst du für 1.080€ brutto (einmalig oder in 2 Raten zahlbar)

Die Buchung erfolgt ausschließlich über ein vereinbartes Vorgespräch, um in den Coachings direkt loslegen zu können.`

const step1Desktop = `
Wir werden innerhalb der 6 Monate deine Hochzeitsplanung starten, planen und gemeinsam durchlaufen. Der große Vorteil ist, dass du mir Mo-Fr, zusätzlich zu unserem Termin der 1x im Monat stattfindet, alle deine Fragen und Gedanken schicken kannst. 
Wir werden uns auf jeden Fall um deine Budgetplanung, die Struktur einer Hochzeitsplanung, die Dienstleisterplanung und den Ablauf am Hochzeitstag kümmern. 
In den meisten Programmen werden lediglich organisatorische Themen besprochen. Ich möchte dir in dieser intensiven Zeit auch die Möglichkeit geben Stress zu reduzieren und Emotionen zu sortieren. 
Das ist dein Glücklich & Zufrieden Brautcoaching Paket: 
${step1MobileText}`

const step2MobileText = `
\u2218 1x Vorgespräch (60 Min.) wir lernen uns kennen und besprechen die relevanten Themen für dich 
\u2218 6x Brautcoaching (je 60 Min.), wir besprechen ein für dich relevantes Thema, du kannst alle Fragen und Anregungen zu deiner Hochzeitsplanung loswerden und du bekommst hilfreiche Unterlagen.
\u2218 6 Wochen Laufzeit & Support via Instagram, du kannst mir Mo-Fr all deine Fragen, aktuelle Stände und Infos geben, wir stehen im ständigen Austausch miteinander, wenn du möchtest.
\u2218 Deine komplette Struktur für die Hochzeitsplanung bekommst du für 600€ brutto (einmalig oder in 2 Raten zahlbar)

Die Buchung erfolgt ausschließlich über ein vereinbartes Vorgespräch, um in den Coachings direkt loslegen zu können. 
` 

const step2Desktop = `
Eine gute Struktur ist schon die halbe Hochzeitsplanung! 
Deshalb biete ich dir im entspannt geplant Brautcoaching den perfekten Start in die Vorbereitungen für euren großen Tag! Du bekommst neben den Online-Coachings einige Unterlagen, die dir einen schnellen und sicheren Überblick geben. Die Aufgabenverteilung, die Budgetplanung, der Ablaufplan am Hochzeitstag und auch Vorbereitungen auf Dienstleistergespräche sind die beliebtesten Themen in diesem Brautcoaching und so so super hilfreich - I promise! 
Wie immer besprechen wir genau die organisatorischen Themen, bei denen du dir Unterstützung wünschst! 

Dein entspannt geplant Brautcoaching Paket:
${step2MobileText}`

const step3MobileText = `
\u2218 1x Vorgespräch (60 Min.) wir lernen uns kennen und besprechen die relevanten Themen für dich 
\u2218 1x Brautcoaching (60 Min.), wir besprechen ein Thema deiner Wahl egal ob du Unterstützung in der Organisation möchtest oder dir ein anderes Hochzeits-Thema auf dem Herzen liegt. 
\u2218 Du bekommst diese schnelle und punktgenaue Hilfe und Unterstützung für 135€ brutto* (einmalig)

Die Buchung erfolgt ausschließlich über ein vereinbartes Vorgespräch, um in den Coachings direkt loslegen zu können. 

*Bei Buchungen des Vorgesprächs bis 31.07.2022 kostet das Kurz&Knackig Brautcoaching 90€ brutto (einmalig).`

const step3Desktop = `
Eine Stunde Hochzeitspower! Ein Thema deiner Wahl! 
Egal ob du nicht weißt wie du deine Einladungen gestalten sollst, ob du den Überblick der Kosten verloren hast, ob du Unterstützung für den Ablauf deiner Hochzeit möchtest oder dir gerade alles zu viel wird und du nicht weißt wo vorne und hinten ist. Lass uns das alles sortieren! Nach dieser Stunde geht es dir besser und kennst deine next Steps. 

Das bekommst du im Kurz&Knackig Brautcoaching:
${step3MobileText}`

const getStepDescriptions = (isMobile: boolean) => [
        <dl style={{whiteSpace: 'break-spaces'}}>
            <dt><Typography variant="h5" component="h3">Glücklich & Zufrieden Brautcoaching</Typography></dt>
            <dd style={isMobile?{fontSize: 'smaller'}:undefined}>
              {isMobile?step1MobileText:step1Desktop}
            </dd>
        </dl>
    ,
    <dl style={{whiteSpace: 'break-spaces'}}>
        <dt><Typography variant="h5" component="h3">entspannt geplant Brautcoaching</Typography></dt>
        <dd style={isMobile?{fontSize: 'smaller'}:undefined}>{isMobile?step2MobileText:step2Desktop}</dd>
    </dl>,
    <dl style={{whiteSpace: 'break-spaces'}}>
        <dt><Typography variant="h5" component="h3">Kurz & Knackig Brautcoaching</Typography></dt>
        <dd style={isMobile?{fontSize: 'smaller'}:undefined}>{isMobile?step3MobileText:step3Desktop}</dd>
    </dl>,

]

const useStyles = makeStyles((theme: any) => ({
  stepIconActive: {
    color: '#9E4BD6 !important',
  },
}));   


export default function CoachingStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const classes = useStyles();
  const smOrLower = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };


  return (
    <Box textAlign="center" display={'flex'} alignContent={"center"}  flexDirection={"column"} marginTop={"30px"} className="descriptionList">
      {!smOrLower && 
        <div style={{maxWidth: '1400px'}}>
          <React.Fragment>
          {getStepDescriptions(smOrLower)[activeStep]}
          </React.Fragment>
      </div>
      } 
      <Stepper nonLinear orientation={smOrLower?"vertical":"horizontal"} activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            {!smOrLower && 
               <StepButton disableRipple color="inherit" onClick={handleStep(index)}>
                <StepLabel 
                  StepIconProps={{ 
                    classes: { active: classes.stepIconActive} 
                  }}
                >
                  {label} 
                </StepLabel>
               </StepButton>
            }
            {smOrLower && 
              <React.Fragment>
                <StepLabel 
                  StepIconProps={{ 
                    classes: { active: classes.stepIconActive} 
                  }}
                  onClick={handleStep(index)}
                >
                  {label} 
                </StepLabel>
                <StepContent>
                  {getStepDescriptions(smOrLower)[activeStep]}
                </StepContent>
              </React.Fragment>
            }
           
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}